import * as Yup from "yup";

const whenStep1Required = {
  is: (step) => step === 1,
  then: Yup.string().required("This field is required"),
};
const whenStep2Required = {
  is: (step) => step === 2,
  then: Yup.string().required("This field is required"),
};
const whenStep3Required = {
  is: (step) => step === 3,
  then: Yup.string().required("This field is required"),
};
const whenStep1andChild = {
  is: (step, form) => step === 1 && /child/i.test(form),
  then: Yup.string().required("This field is required"),
};
const whenStep2andChild = {
  is: (step, form) => step === 2 && /child/i.test(form),
  then: Yup.string().required("This field is required"),
};
const whenStep3andChild = {
  is: (step, form) => step === 3 && /child/i.test(form),
  then: Yup.string().required("This field is required"),
};
const whenStep1andSelf = {
  is: (step, form) => step === 1 && /self/i.test(form),
  then: Yup.string().required("This field is required"),
};

export const validationSchema = Yup.object().shape({
  formInfo: Yup.string(),

  // =========== form child & self ==========
  // * step 1
  fullName: Yup.string().when(["step"], whenStep1Required),
  dob: Yup.string().when(["step"], whenStep1Required),
  gender: Yup.string().when(["step"], whenStep1Required),
  todayDate: Yup.string().when(["step"], whenStep1Required),

  // * step 2
  father: Yup.string().when(["step", "maritalStatus", "mother"], {
    is: (step, maritalStatus, mother) =>
      step === 2 && maritalStatus !== "Not Married" && mother !== "",
    then: Yup.string(),
  }),
  mother: Yup.string().when(["step", "maritalStatus"], {
    is: (step, maritalStatus) => step === 2 && maritalStatus !== "Not Married",
    then: Yup.string(),
  }),
  maritalStatus: Yup.string().when(["step"], whenStep2Required),
  householdMembers: Yup.array().when(["step"], {
    is: (step) => step === 2,
    then: Yup.array().test(
      "householdMembers",
      "Please complete all of the fields",
      (value) => {
        let status = value.map((item) => {
          return (
            item.hasOwnProperty("name") &&
            !!item["name"] &&
            item.hasOwnProperty("age") &&
            !!item["age"] &&
            item.hasOwnProperty("relationship") &&
            !!item["relationship"]
          );
        });
        return !status.includes(false);
      }
    ),
  }),
  childsSignificantOthers: Yup.array().when(["step"], {
    is: (step) => step === 2,
    then: Yup.array().test(
      "childsSignificantOthers",
      "Please complete all of the fields",
      (value) => {
        let status = value.map((item) => {
          return (
            item.hasOwnProperty("name") &&
            !!item["name"] &&
            item.hasOwnProperty("age") &&
            !!item["age"] &&
            item.hasOwnProperty("relationship") &&
            !!item["relationship"]
          );
        });
        return !status.includes(false);
      }
    ),
  }),

  // * step 3
  childEverExperiencedAbuse: Yup.string().when(["step"], whenStep3Required),
  childEverMadeStatementsOfWantingToHurt: Yup.string().when(
    ["step"],
    whenStep3Required
  ),
  childEverPurposelyHurtHimself: Yup.string().when(["step"], whenStep3Required),
  childEverExperiencedAnySeriousEmitionalLosses: Yup.string().when(
    ["step"],
    whenStep3Required
  ),
  thingsThatAreCurrentlyStressful: Yup.string().when(
    ["step"],
    whenStep3Required
  ),

  // =========== form child ==========
  // * step 1
  behaviouralExcesses: Yup.string().when(["step", "formInfo"], {
    is: (step, form) => step === 1 && form === "child",
    then: Yup.string().required("This field is required"),
  }),
  behaviouralDeficits: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andChild
  ),
  behaviouralAssets: Yup.string().when(["step", "formInfo"], whenStep1andChild),
  otherConcerns: Yup.string().when(["step", "formInfo"], whenStep1andChild),
  treatmentGoals: Yup.string().when(["step", "formInfo"], whenStep1andChild),

  // * step 2
  describePastCounselling: Yup.string().when(
    ["step", "formInfo"],
    whenStep2andChild
  ),

  // * step 3
  schoolName: Yup.string().when(["step", "formInfo"], whenStep3andChild),
  formTeacherName: Yup.string().when(["step", "formInfo"], whenStep3andChild),
  currentClass: Yup.string().when(["step", "formInfo"], whenStep3andChild),
  childsTeacherSayAbout: Yup.string().when(
    ["step", "formInfo"],
    whenStep3andChild
  ),
  childEverRepeatedAClass: Yup.string().when(
    ["step", "formInfo"],
    whenStep3andChild
  ),
  childEverReceivedEducationServices: Yup.string().when(
    ["step", "formInfo"],
    whenStep3andChild
  ),
  childExperiencedProblems: Yup.array(),

  // =========== form self ==========
  suggestedToSeekCounselling: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andSelf
  ),
  reasonsForSeekingCounselling: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andSelf
  ),
  gainFromBeingInCounselling: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andSelf
  ),
  yourStrengths: Yup.string().when(["step", "formInfo"], whenStep1andSelf),
  everCounseled: Yup.string().when(["step", "formInfo"], whenStep1andSelf),
  helpingProfessional: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andSelf
  ),
  wasItAGoodExperience: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andSelf
  ),
  listAnyMedications: Yup.string().when(["step", "formInfo"], whenStep1andSelf),
  toTheHospitalForMentalHealth: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andSelf
  ),
  toTheHospitalForSubstanceUse: Yup.string().when(
    ["step", "formInfo"],
    whenStep1andSelf
  ),
});
